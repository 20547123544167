import React, { useState } from "react";
import Swal from "sweetalert2";
import { adminLoginURL } from "./api/config";
import { adminLoginService } from "./api/_api";
import logo2 from "./assets/images/tumble_logo.jpeg";
import DiscountComponent from "./Components/DiscountComponent";
import DynamicInputFields from "./Components/DynamicInputFields";
import InputComponent from "./Components/InputComponent";
import moment from "moment/moment";

const Admin = () => {
  const [steps, setSteps] = useState(0);
  const [seletedDate, setSelectedDate] = useState(
    moment(new Date).format("DD/MM/YYYY")
  );
  const nextSteps = (step) => {
    setSteps(step);
  };
 
  const [password, setPassword] = useState(null);
 

  const onSubmitClick = (event) => {
    event.preventDefault();
    const payload = {
      password: password,
    };
    
    adminLoginService(adminLoginURL, payload)
      .then((res) => {
        if (res) {
          // setTableData(res?.data);
          if (res?.message == "Logged in successfully") {
            Swal.fire({
              icon: "success",
              title: "",
              text: res?.message,
            });
            nextSteps(1);
            setPassword(null);
          }
        }
      })
      .catch((err) => console.log("slotDetailsService", err));
  };

  return (
    <body>
      <div className="register-photo">
        <div className="form-container">
          <form className="align-middle" method="post">
            <div className="d-flex mb-3 justify-content-center">
              <img
                className=""
                src={logo2}
                style={{ height: "20%", width: "20%" }}
              />
            </div>
            <h2 className="text-center fs-5">
              <strong>Welcome</strong> to Tumble Duplo Admin Panel.
            </h2>
            {steps == 0 ? (
              <div className="form-group">
                <div className="form-group mt-2" style={{ textAlign: "left" }}>
                  <InputComponent
                    iClass="form-control"
                    iType="password"
                    iId="ID2"
                    iPlaceholder="Please Enter Password"
                    iName="pass"
                    //   iValue={formData?.childDate}
                    iChangeHandler={(e) => setPassword(e.target.value)}
                    //   iErrors={formDataErrors}
                    iLabel=""
                  />
                  <button
                    // onClick={() => nextSteps(1)}
                    onClick={(event) => onSubmitClick(event)}
                    className="btn shadow btn-info mt-2 text-white btn-sm"
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : null}
            {steps == 1 ? (
              <div className="form-group">
                <div className="form-group mt-2" style={{ textAlign: "left" }}>
                  <InputComponent
                    iClass="form-control"
                    iType="date"
                    iId="ID2"
                    iPlaceholder="Select child date"
                    iName="childDate"
                    //   iValue={formData?.childDate}
                    iChangeHandler={(event) =>
                      setSelectedDate(event.target.value)
                    }
                    //   iErrors={formDataErrors}
                    iLabel="Select date to view slot"
                  />
                  <DynamicInputFields
                    seletedDate={seletedDate}
                    nextSteps={nextSteps}
                  />
                  {/* <button
                    onClick={() => nextSteps(0)}
                    className="btn mx-2 mt-2 shadow btn-warning btn-sm text-light"
                  >
                    Previous
                  </button>
                  <button
                    onClick={nextSteps}
                    className="btn shadow mt-2 btn-info text-white btn-sm"
                  >
                    Save
                  </button> */}
                </div>
              </div>
            ) : null}
            {steps == 2 ? <DiscountComponent nextSteps={nextSteps} /> : null}
          </form>
        </div>
      </div>
    </body>
  );
};

export default Admin;
