import React, { useState } from "react";
import CryptoJS from "crypto-js";
import { onlinePaymentService } from "../../api/_api";
import { paymentURL } from "../../api/config";
import Swal from "sweetalert2";

const Payu = () => {
  
const handlePayment = (e) => {
  e.preventDefault();
  const payload = {
    amount: 600,
    productInfo: "Tumble Duplo Class",
    firstName: "parent name",
    email: "john@example.com",
    phone: "1234567890",
    udf4: "child name",
    udf5: "2023-07-20",
    udf1: "2023-07-08",
    udf2: "12:00pm-1:00pm--25--650",
    udf3: 1,
    term: "on",
  };

  onlinePaymentService(paymentURL, payload)
    .then((res) => {
      if (res) {
        // setTableData(res?.data);
        Swal.fire({
          icon: "success",
          title: "",
          text: res?.message,
        });
    
        // setOtpVerified(true);
        // setSteps((step) => step + 1);
      } else {
        // Swal.fire({
        //   icon: "error",
        //   title: "",
        //   text: "Invalid OTP",
        // });
  
      }
    })
    .catch((err) => console.log("payment error", err));
};
  return (
    <div>
      <h2>PayU Integration</h2>
      <button onClick={(event) => handlePayment(event)}>Make Payment</button>
    </div>
  );
};

export default Payu;
