import React, { useState, useEffect } from "react";
import "./App.css";
import logo2 from "./assets/images/tumble_logo.jpeg";
import InputComponent from "./Components/InputComponent";
import moment from "moment/moment";
import Swal from "sweetalert2";
import {
  searchParentService,
  numberDetailsService,
  slotDetailsService,
  adminLoginService,
  cashDataService,
  sendWhatsappOtpService,
  verifyOtpService,
  onlinePaymentService,
} from "./api/_api";
import {
  searchParentURL,
  numberDetailsURL,
  slotDetailsURL,
  adminLoginURL,
  cashDataURL,
  sendWhatsappOtp,
  verifyOtp,
  paymentURL,
} from "./api/config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DropdownListComponent from "./Components/DropdownListComponent";
import RadioInputComponent from "./Components/RadioInputComponent";
import { isEmpty } from "./helpers/isEmpty";
import Payu from "./src/payment/Payu";

const User = () => {
  const [showMore, setShowMore] = useState(false);
  const [otpButton, setOtpButton] = useState(false);
  const [formDataErrors, setFormDataErrors] = useState([]);
  const [otpVerified, setOtpVerified] = useState(false);
  const [steps, setSteps] = useState(0); // 0 | 1 | 2 | 3 | 4 |
  const [parentCashNumber,setParentCashNumber] = useState("")
  console.log("parent numberin cashform", parentCashNumber.length)
  const [formData, setFormData] = useState({
    contactNumber: "",
    otp: "",
    userId: "",
    numberOfChild: "",
    parentEmail: "",
    isClientFound: false,
    autoFillTrigger: false,
    contactNumberAutofillList: [],
    slotDetailsList: [],
    selectedSlot: {},
    isDiscount: {},
    paymentMode: "",
    parentName: "",
    childName: "",
    date: new Date(), // YYYY/MM/DD (service required this format ) || new Date() (Date picker requiered this format)
    childDate: "",
    isAdmin: false,
    pass: "",
    isMember: -1,
    calucatedAmount: "",
    policy: false,
    isStaff: 0,
    instruction: "",
    modeOfPayment:"Cash"
  });
  // Credit Card
  // Not Showing
  // Cash
  const onShowmoreLick = (event) => {
    event.preventDefault();
    setShowMore(!showMore);
  };
  const handlePaymentModeChange = (event) => {
    const newPaymentMode = event.target.value;
    setFormData({ ...formData, modeOfPayment: newPaymentMode });
  };
  const handleInput = (e, type, riType) => {
    let { name, id, value, checked } = e.target;

    setFormDataErrors([]);
    if (type == "text" || type == "email") {
      setFormData({ ...formData, [name]: value });
    }

    if (type == "radio") {
      if (riType == "paymentType") {
        setFormData({ ...formData, [name]: value });
      }
      if (riType == "slotArray") {
        setFormData({ ...formData, selectedSlot: JSON.parse(value) });
      }
    }

    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    }

    if (type == "number") {
      if (name === "otp") {
        setFormData({
          ...formData,
          [name]: value.slice(0, 6),
          autoFillTrigger: false,
          isClientFound: false,
        });
      } else {

        // if(name === "numberOfChild" && value===0){
        //   Swal.fire({
        //     icon: "info",
        //     title: "Oops...",
        //     text: "Sorry! Please fill atleast 1 slot.",
        //   });
        //   value = 1;
        // }

        setFormData({
          ...formData,
          [name]: value,
          autoFillTrigger: false,
          isClientFound: false,
        });
      }
    }
    if (type == "date" && name == "childDate") {
      setFormData({ ...formData, [name]: value });
    }

    if (type == "date" && name !== "childDate") {
      if (moment(value).format("LLLL").split(",")[0] === "Sunday") {
        Swal.fire({
          icon: "info",
          title: "Oops...",
          text: "Sorry! Sunday not allowed.",
        });
      }
    }
  };

  const showDropdownFn = () => {
    const { contactNumberAutofillList, autoFillTrigger } = formData;
    return contactNumberAutofillList?.length > 0 && autoFillTrigger === true;
  };

  const fullObjectPickerFn = (fullObj) => {
    const obj = fullObj;
    setFormData({
      ...formData,
      isClientFound: true,
      userId: obj?.id,
      contactNumber: obj?.parent_number,
      contactNumberAutofillList: [],
      autoFillTrigger: false,
    });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setOtpButton(true);
    }, 30000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

const handleParentNumberChange = (e) => {
  console.log("parent number in staff user", e.target.value);
  setParentCashNumber(e.target.value);
  if (e.target.value.length == 10) {
    numberDetailsService(numberDetailsURL, {
      number: e.target.value,
    })
      .then((res) => {
        if (res) {
          setFormData({
            ...formData,
            isDiscount: res?.data?.isDiscount,
          });
          amountCalculation(res?.data?.isDiscount);

        }
      })
      .catch((err) => console.log("numberDetailsService", err));

  }


}

const handleSelectedslots = (e) => {
  // console.log("kkkkkk kkkkkkk kkkkkkkkkk k ", e.target.value, typeof e.target.value)
  if(e.target.value == ""){
    setFormData({ ...formData, numberOfChild: "" });
  }
  else if (e.target.value < 1) {
    setFormData({ ...formData, numberOfChild: Number(1) });
  }
  else{
    setFormData({ ...formData, numberOfChild: Number(e.target.value) });
  }
}


  useEffect(() => {
    if (formData?.isStaff === 1) {
      setFormData({ ...formData, paymentMode: "manual" });
    } else {
      setFormData({ ...formData, paymentMode: "online" });
    }
  }, [formData.isStaff]);

  const validation = () => {
    const errs = [];
    const { parentName, childName, contactNumber, date, childDate } = formData;
    if (steps === 0) {
      if (contactNumber?.length !== 10) {
        errs.push({
          errorIn: "contactNumber",
          text: "Please enter a valid contact number.",
        });
      }
    } else if (steps === 2) {
      if (parentName.length < 2) {
        errs.push({
          errorIn: "parentName",
          text: "Please enter parent name.",
        });
      }
      if (childName.length < 2) {
        errs.push({
          errorIn: "childName",
          text: "Please enter child name.",
        });
      }

      if (date?.length < 1) {
        errs.push({
          errorIn: "date",
          text: "Please select slot date.",
        });
      }
      if (childDate?.length < 1) {
        errs.push({
          errorIn: "childDate",
          text: "Please select child DOB.",
        });
      }
    } else if (steps === 4) {
      if (isEmpty(formData?.selectedSlot)) {
        errs.push({
          errorIn: "selectedSlot",
          text: "Please select any slot.",
        });
      }
    } else if (steps === 5) {
      if (formData?.numberOfChild?.length < 1) {
        errs.push({
          errorIn: "numberOfChild",
          text: "Please enter number of children or seats.",
        });
      }
      const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (
        formData?.parentEmail?.length < 1 ||
        emailRegx.test(formData?.parentEmail) === false
      ) {
        errs.push({
          errorIn: "parentEmail",
          text: "Please enter a valid email id.",
        });
      }
    }

    if (errs.length > 0) {
      setFormDataErrors(errs);
    }
    return errs?.length > 0 ? true : false;
  };

  const showError = (errorIn) => {
    const errorFound = formDataErrors?.find((err) => err?.errorIn === errorIn);
    return errorFound?.text || null;
  };

  const onOtpVerify = (e) => {
    e.preventDefault();
    const payload = {
      number: formData?.contactNumber,
      otp: formData?.otp,
    };

    verifyOtpService(verifyOtp, payload)
      .then((res) => {
        if (res) {
          // setTableData(res?.data);
          Swal.fire({
            icon: "success",
            title: "",
            text: res?.message,
          });

          setOtpVerified(true);
          setSteps((step) => step + 1);
        } else {
          Swal.fire({
            icon: "error",
            title: "",
            text: "Invalid OTP",
          });
        }
      })
      .catch((err) => console.log("slotDetailsService", err));
  };
  const amountCalculation = (parentDiscount) => {
    if (!parentDiscount) {
const { numberOfChild, selectedSlot, isDiscount } = formData;
const maxPrice = Number(numberOfChild) * selectedSlot?.price;
let discountedPrice = null;
if (isDiscount?.type === "Normal") {
  setFormData({ ...formData, calucatedAmount: Number(maxPrice), isMember: -1 });
  // return Number(maxPrice);
}

if (isDiscount?.type === "Discount") {
  discountedPrice = maxPrice * ((100 - Number(isDiscount?.value)) / 100);
  setFormData({ ...formData, calucatedAmount: Number(discountedPrice),  isMember: -1 });
  // return Number(discountedPrice);
}
if (isDiscount?.type === "Membership") {
  discountedPrice = maxPrice - Number(isDiscount?.value) * selectedSlot?.price;

  const member =
    Number(numberOfChild) - Number(isDiscount?.value) < 0
      ? numberOfChild
      : isDiscount?.value;

  setFormData({ ...formData, isMember: member });

  setFormData({
    ...formData,
    calucatedAmount: Number(discountedPrice) < 0 ? 0 : Number(discountedPrice),
    isMember: Number(member),
  });
  // return ;
}
    } else {
      const { numberOfChild, selectedSlot, isDiscount } = formData;
      const maxPrice = Number(numberOfChild) * selectedSlot?.price;
      let discountedPrice = null;
      if (parentDiscount?.type === "Normal") {
        setFormData({ ...formData, calucatedAmount: Number(maxPrice) });
        // return Number(maxPrice);
      }

      if (parentDiscount?.type === "Discount") {
        discountedPrice =
          maxPrice * ((100 - Number(parentDiscount?.value)) / 100);
        setFormData({ ...formData, calucatedAmount: Number(discountedPrice) });
        // return Number(discountedPrice);
      }
      if (parentDiscount?.type === "Membership") {
        discountedPrice =
          maxPrice - Number(parentDiscount?.value) * selectedSlot?.price;

        const member =
          Number(numberOfChild) - Number(parentDiscount?.value) < 0
            ? numberOfChild
            : parentDiscount?.value;

        setFormData({ ...formData, isMember: member });

        setFormData({
          ...formData,
          calucatedAmount:
            Number(discountedPrice) < 0 ? 0 : Number(discountedPrice),
          isMember: Number(member),
        });
        // return ;
      }
    }
    
    
  };
    const amountCalculationStaff = (parentDiscount) => {
      
      console.log("parent discoun", parentDiscount);
      const { numberOfChild, selectedSlot, isDiscount } = formData;
      const maxPrice = Number(numberOfChild) * selectedSlot?.price;
      let discountedPrice = null;
      if (isDiscount?.type === "Normal") {
        setFormData({ ...formData, calucatedAmount: Number(maxPrice) });
        // return Number(maxPrice);
      }

      if (isDiscount?.type === "Discount") {
        discountedPrice =
          maxPrice * ((100 - Number(parentDiscount?.value)) / 100);
        setFormData({ ...formData, calucatedAmount: Number(discountedPrice) });
        // return Number(discountedPrice);
      }
      if (isDiscount?.type === "Membership") {
        discountedPrice =
          maxPrice - Number(parentDiscount?.value) * selectedSlot?.price;

        const member =
          Number(numberOfChild) - Number(parentDiscount?.value) < 0
            ? numberOfChild
            : parentDiscount?.value;

        setFormData({ ...formData, isMember: member });

        setFormData({
          ...formData,
          calucatedAmount:
            Number(discountedPrice) < 0 ? 0 : Number(discountedPrice),
          isMember: Number(member),
        });
        // return ;
      }
    };
  const nextSteps = (e) => {
    e.preventDefault();
    if (steps === 0) {
      if (validation()) return;

      // otp sending service start
      const payload = {
        number: formData?.contactNumber,
      };

      sendWhatsappOtpService(sendWhatsappOtp, payload)
        .then((res) => {
          if (res) {
            // setTableData(res?.data);

            if(res?.message==="staff"){
              setOtpVerified(true);
              setSteps((step) => step + 1);
            }
            else{
              Swal.fire({
                icon: "success",
                title: "",
                text: res?.message,
              });
            }

          }
        })
        .catch((err) => console.log("slotDetailsService", err));

      // otp sending service end
      if (formData?.contactNumber?.length === 10) {
          numberDetailsService(numberDetailsURL, {
            number: formData?.contactNumber,
          })
            .then((res) => {
              if (res) {
                setFormData({
                  ...formData,
                  isDiscount: res?.data?.isDiscount,
                  childName: res?.data?.userData?.child_name,

                  childDate: res?.data?.userData?.child_dob
                    ?.split("/")
                    .join("-"),
                  parentName: res?.data?.userData?.parent_name,
                  isStaff: res?.data?.userData?.isStaff,
                  instruction:res?.data?.warning_info
                });
              }
            })
            .catch((err) => console.log("numberDetailsService", err));
          // if (steps === 0 && formData?.userId && formData?.isClientFound) {

          // }
          setSteps((step) => step + 1);
      }else {
         Swal.fire({
           icon: "error",
           title: "",
           text: "Please Enter 10 Digit Phone Number",
         });
      }
    
    }
    if (steps === 2) {
      if (validation()) return;
      if (formData?.date) {
        slotDetailsService(slotDetailsURL, {
          date: moment(formData?.date).format("YYYY/MM/DD"),
          isAdminSlots:0
        })
          .then((res) => {
            if (res) {
              if (res?.data?.length === 0) {
                Swal.fire({
                  icon: "info",
                  title: "Oops...",
                  text: "Sorry! slots not available.",
                });
                return;
              } else {
                setFormData({ ...formData, slotDetailsList: res?.data });
                setSteps((step) => step + 1);
              }
            }
          })
          .catch((err) => console.log("slotDetailsService", err));
      }
      // setSteps((step) => step + 1);
    }
    if (steps === 3) {
      if (validation()) return;
      setSteps((step) => step + 1);
    }
    if (steps === 4) {
      // debugger;
      if (validation()) return;
      amountCalculation();

      setSteps((step) => step + 1);
    }
    if (steps === 5) {
      if (validation()) return;
      setSteps((step) => step + 1);
    }
  };

  const previousSteps = (e) => {
    e.preventDefault();
    if (steps === 5) {
      setFormData({ ...formData, paymentMode: "" });
    }
    if (steps === 4) {
      setFormDataErrors([]);
      setFormData({ ...formData, numberOfChild: "", parentEmail: "" });
    }
    if (steps === 3) {
      setFormDataErrors([]);
      setFormData({ ...formData, selectedSlot: {} });
    }
    if (steps === 2) {
      setFormData({
        ...formData,
        parentName: "",
        childName: "",
        childDate: "",
        contactNumber: "",
        date: "",
        autoFillTrigger: "false",
      });
    }
    setSteps((step) => step - 1);
  };
  // console.log("formdata from state", formData);
  const handleSignupSubmit = (e) => {
    // debugger
    // e.preventDefault();
    e.preventDefault();

    if(!formData?.parentEmail){
      Swal.fire({
        icon: "error",
        title: "",
        text: "Email is required.",
      });

      return;
    }

    // if (formData?.paymentMode !=="online") {
    if (formData?.paymentMode === "online") {
      // setSteps(6);
      // debugger;
      const payload = {
        amount: formData?.calucatedAmount,
        productInfo: "Child Slot Book",
        firstName: formData?.parentName,
        email: formData?.parentEmail,
        phone: formData?.contactNumber,
        udf4: formData?.childName,
        udf5: formData?.childDate,
        udf1: moment(formData?.selectedSlot?.slot_date).format("YYYY-MM-DD"),
        udf2:
          formData?.selectedSlot?.start_time +
          "-" +
          formData?.selectedSlot?.end_time,
        udf3: formData?.numberOfChild,
        term: "on",
      };

      onlinePaymentService(paymentURL, payload)
        .then((res) => {
          if (res) {
          
            console.log("create slot", res);
    ;
          } else {
       
            console.log("error");
          }
        })
        .catch((err) => console.log("payment error", err));
    } 
    // else if(formData?.paymentMode === "online" && formData?.calucatedAmount==0){

    //             const req = {
    //               parentName: formData?.parentName,
    //               childName: formData?.childName,
    //               childDOB: formData?.childDate,
    //               parentNumber: formData?.contactNumber,
    //               parentEmail: formData?.parentEmail,
    //               amount: formData?.calucatedAmount,
    //               seats: Number(formData?.numberOfChild),
    //               startTime: formData?.selectedSlot?.start_time,
    //               endTime: formData?.selectedSlot?.end_time,
    //               slotDate: formData?.selectedSlot?.slot_date.split("/").join("-"),
    //               isMember: 1,
    //               modeOfPayment: "Membership",
    //               staffNumber: formData?.contactNumber,
    //             };

    //     cashDataService(cashDataURL, req)
    //               .then(({ data, message }) => {
    //                 Swal.fire({
    //                   icon: "success",
    //                   text: message,
    //                 });
    //                 setFormData({
    //                   contactNumber: "",
    //                   userId: "",
    //                   numberOfChild: "",
    //                   parentEmail: "",
    //                   isClientFound: false,
    //                   autoFillTrigger: false,
    //                   contactNumberAutofillList: [],
    //                   slotDetailsList: [],
    //                   selectedSlot: {},
    //                   isDiscount: {},
    //                   paymentMode: "",
    //                   parentName: "",
    //                   childName: "",
    //                   date: "", // YYYY/MM/DD (service required this format ) || new Date() (Date picker requiered this format)
    //                   childDate: "",
    //                   isAdmin: false,
    //                   pass: "",
    //                   isMember: -1,
    //                 });
    //                 setSteps(0);
    //                 window.location.reload();
    //               })
    //               .catch((err) => console.log("cashDataService", err));
    // } 
    else{
      const payload = {
        password: formData?.pass,
      };
      adminLoginService(adminLoginURL, payload)
        .then(async (res) => {
          if (res) {
            if (res?.message == "Logged in successfully") {

              setFormData({ ...formData, pass: "" });
              const {
                parentName,
                childName,
                childDate,
                contactNumber,
                parentEmail,
                numberOfChild,
                selectedSlot,
                isMember,
                calucatedAmount,
              } = formData;

              const req = {
                parentName: parentName,
                childName: childName,
                childDOB: childDate,
                parentNumber: parentCashNumber,
                parentEmail: parentEmail,
                amount: calucatedAmount,
                seats: Number(numberOfChild),
                startTime: selectedSlot?.start_time,
                endTime: selectedSlot?.end_time,
                slotDate: selectedSlot?.slot_date.split("/").join("-"),
                isMember: Number(isMember),
                modeOfPayment: formData?.modeOfPayment,
                staffNumber: contactNumber,
              };

              cashDataService(cashDataURL, req)
                .then(({ data, message }) => {
                  Swal.fire({
                    icon: "success",
                    text: message,
                  });
                  setFormData({
                    contactNumber: "",
                    userId: "",
                    numberOfChild: "",
                    parentEmail: "",
                    isClientFound: false,
                    autoFillTrigger: false,
                    contactNumberAutofillList: [],
                    slotDetailsList: [],
                    selectedSlot: {},
                    isDiscount: {},
                    paymentMode: "",
                    parentName: "",
                    childName: "",
                    date: "", // YYYY/MM/DD (service required this format ) || new Date() (Date picker requiered this format)
                    childDate: "",
                    isAdmin: false,
                    pass: "",
                    isMember: -1,
                  });
                  setSteps(0);
                  window.location.reload();
                })
                .catch((err) => console.log("cashDataService", err));
            }
          }
        })
        .catch((err) => console.log("adminLoginService", err));
    }
  };

  useEffect(() => {
    if (formData?.isClientFound === false) {
      if (formData?.contactNumber?.length > 3) {
        const timer = setTimeout(
          () =>
            searchParentService(searchParentURL, {
              phoneNumber: formData?.contactNumber,
            })
              .then((res) => {
                if (res) {
                  setFormData({
                    ...formData,
                    contactNumberAutofillList: res?.data,
                    autoFillTrigger: true,
                  });
                }
              })
              .catch((err) => console.log("searchParentService", err)),
          500
        );

        return () => {
          clearTimeout(timer);
        };
      }
    } else {
      return;
    }
  }, [formData?.contactNumber]);
  useEffect(() => {
    if (steps === 4) {

      if (validation()) return;
      amountCalculation();

    }

  }, [steps, formData.numberOfChild,parentCashNumber]);
  
  // console.log("formData", formData);
  // console.log("steps", steps);
  return (
    <body>
      <div className="register-photo">
        <div className="form-container">
          <div
            style={{
              background:
                "url(https://cdn.pixabay.com/photo/2023/01/20/12/15/school-7731567_960_720.jpg)",
              backgroundSize: "contain",
            }}
            className="image-holder"
          ></div>
          <form className="align-middle" method="post">
            <div className="d-flex mb-3 justify-content-center">
              <img className="h-50 w-50" src={logo2} />
            </div>
            <h2 className="text-center fs-5">
              <strong>Welcome</strong> to Tumble House Duplo.
            </h2>
            {steps === 0 && (
              <div className="form-group">
                <InputComponent
                  iClass="form-control"
                  iType="number"
                  iId="IN1"
                  iLabel="Enter your contact number."
                  iName="contactNumber"
                  iValue={formData?.contactNumber}
                  iChangeHandler={(e) => handleInput(e, "number")}
                  iErrors={formDataErrors}
                />
                {showDropdownFn() && (
                  <DropdownListComponent
                    itemKey={`parent_number`}
                    ulClass={`drop-list shadow-sm p-0`}
                    listArray={formData?.contactNumberAutofillList}
                    itemObjectTakerFn={(fullObj) => fullObjectPickerFn(fullObj)}
                  />
                )}
              </div>
            )}
            {steps === 1 && (
              <div className="form-group">
                <InputComponent
                  iClass="form-control"
                  iType="number"
                  iId="IN1"
                  iLabel="Enter your OTP"
                  iName="otp"
                  iValue={formData?.otp?.slice(0, 6)}
                  iChangeHandler={(e) => handleInput(e, "number")}
                  iErrors={formDataErrors}
                  iMaxL={"6"}
                />
            
              </div>
            )}
            {steps === 2 && (
              <>
                <div className="form-group">
                  <InputComponent
                    iClass="form-control"
                    iType="text"
                    iId="IT1"
                    iLabel="Please Enter One Parent Name."
                    iName="parentName"
                    iValue={formData?.parentName}
                    iChangeHandler={(e) => handleInput(e, "text")}
                    iErrors={formDataErrors}
                  />
                </div>
                <div className="form-group mt-2">
                  <InputComponent
                    iClass="form-control"
                    iType="text"
                    iId="IT2"
                    iLabel="Please Enter One Child Name."
                    iName="childName"
                    iValue={formData?.childName}
                    iChangeHandler={(e) => handleInput(e, "text")}
                    iErrors={formDataErrors}
                  />
                </div>
                <div className="form-group mt-2" style={{ textAlign: "left" }}>
                  <InputComponent
                    iClass="form-control"
                    iType="date"
                    iId="ID2"
                    iPlaceholder="Select child date"
                    iName="childDate"
                    iValue={formData?.childDate}
                    iChangeHandler={(e) => handleInput(e, "date")}
                    iErrors={formDataErrors}
                    iLabel="Select child date of birth."
                  />
                </div>
                <div className="form-group mt-2">
                  <label className="form-check-label">
                    Slot Date For Booking.
                  </label>
                  <DatePicker
                    minDate={new Date()}
                    maxDate={moment().add(3, "days").toDate()} // Convert to JavaScript Date object
                    className="form-control"
                    value={moment(formData?.date).format("DD/MM/YYYY")} // Use value instead of selected
                    format="dd/MM/yyyy" // Adjust the format here
                    onChange={(date) =>
                      setFormData({ ...formData, date: date })
                    }
                  />
              
                </div>
              </>
            )}

            {steps === 3 && (
              <>
                <div className="form-group">
                  {formData?.slotDetailsList?.map((slot) => (
                    <>
                 
                      <RadioInputComponent
                        riName="selectedSlot"
                        radioType="slotArray"
                        slotObj={slot}
                        riValue={JSON.stringify(slot)}
                        riIsChecked={slot?.id === formData?.selectedSlot?.id}
                        riChangeHandler={(e) =>
                          handleInput(e, "radio", "slotArray")
                        }
                      />
                
                    </>
                  ))}
                </div>
                {formDataErrors?.length > 0 && (
                  <small className="text-danger">
                    {showError("selectedSlot")}
                  </small>
                )}
              </>
            )}

            {steps === 4 && (
              <>
                <div className="form-group">
                  <InputComponent
                    iClass="form-control"
                    iType="number"
                    iId="IN2"
                    iLabel="Number of kids."
                    iName="numberOfChild"
                    iValue={formData?.numberOfChild}
                    iChangeHandler={(e) => handleSelectedslots(e)}
                    iErrors={formDataErrors}
                  />
                </div>
                <small className="fs-11 text-warning">
                  {formData?.instruction?.slotInstruction}
                </small>
                <div className="form-group mt-3">
                  <InputComponent
                    iClass="form-control"
                    iType="email"
                    iId="IE1"
                    iLabel="Enter your email."
                    iName="parentEmail"
                    iValue={formData?.parentEmail}
                    iChangeHandler={(e) => handleInput(e, "email")}
                    iErrors={formDataErrors}
                    required={true}
                  />
            
                </div>
           
                {formData?.paymentMode === "manual" && (
                  <>
                    <div className="form-group mt-3 mb-3">
                      <InputComponent
                        iClass="form-control"
                        iType="number"
                        iId="IN2"
                        iLabel="Parent Number."
                        iName="staffNumber"
                        // iValue={formData?.numberOfChild}
                        iChangeHandler={(e) =>
                          handleParentNumberChange(e)
                        }
                        iErrors={formDataErrors}
                      />
                    </div>

                    <div className="form-group mt-3 mb-3">
                      <InputComponent
                        iDisabled="true"
                        iClass="form-control"
                        iType="number"
                        iId="IN2"
                        iLabel="Amount"
                        iName=""
                        iValue={formData?.calucatedAmount}
              
                      />
                    </div>
                    <div className="form-group mt-3 mb-3">
                      <label htmlFor="modeOfPayment" className="me-2">
                        Mode of Payment:{" "}
                      </label>
                      <select
                        id="modeOfPayment"
                        name="modeOfPayment"
                        value={formData.modeOfPayment}
                        onChange={handlePaymentModeChange}
                      >
                        <option value="Credit Card">Credit Card</option>
                        <option value="Not Showing">Not Showing</option>
                        <option value="Cash">Cash</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <InputComponent
                        iClass="form-control"
                        iType="password"
                        iId="IE1"
                        iPlaceholder="Enter admin password."
                        iName="pass"
                        iValue={formData?.pass}
                        iChangeHandler={(e) => handleInput(e, "text")}
                        iErrors={formDataErrors}
                      />
                    </div>
                  </>
                )}
                <div className="form-group">
                  <div className="form-check">
                    <label className="form-check-label m-2">
                      <input
                        checked={formData?.policy}
                        onChange={(e) => handleInput(e, "checkbox")}
                        name="policy"
                        className="form-check-input mt-1"
                        type="checkbox"
                      />
                      <a
                        href="https://sites.google.com/tumbleduplo.com/disclaimer-tumble-duplo/home"
                        target="_blank"
                      >
                        agree to the license terms.
                      </a>
                    </label>
                  </div>
                </div>
              </>
            )}

            {steps === 5 && (
              <>
                <div className="form-group p-auto">
               
                </div>
                {formData?.paymentMode === "manual" && (
                  <div className="form-group">
                    <InputComponent
                      iClass="form-control"
                      iType="password"
                      iId="IE1"
                      iPlaceholder="Enter admin password."
                      iName="pass"
                      iValue={formData?.pass}
                      iChangeHandler={(e) => handleInput(e, "text")}
                      iErrors={formDataErrors}
                    />
                  </div>
                )}
                <div className="form-group">
                  <div className="form-check">
                    <label className="form-check-label m-2">
                      <input
                        checked={formData?.policy}
                        onChange={(e) => handleInput(e, "checkbox")}
                        name="policy"
                        className="form-check-input mt-1"
                        type="checkbox"
                      />
                      <a
                        href="https://sites.google.com/tumbleduplo.com/disclaimer-tumble-duplo/home"
                        target="_blank"
                      >
                        agree to the license terms.
                      </a>
                    </label>
                  </div>
                </div>
              </>
            )}
            {steps === 6 ? <Payu /> : null}
            <div className="form-group mt-2 d-flex justify-content-end">
              {steps > 0 && (
                <button
                  onClick={previousSteps}
                  className="btn mx-2 shadow btn-warning btn-sm text-light"
                  disabled={steps === 1 && otpButton === false ? true : false}
                >
                  {steps === 1 ? "Did not receive OTP go back" : "Previous"}
                </button>
              )}
              {steps < 4 && steps !== 1 && (
                <button
                  onClick={nextSteps}
                  className="btn shadow btn-info text-white btn-sm"
                  disabled={
                    steps === 3 && !formData?.selectedSlot?.slot_date
                      ? true
                      : false
                  }
                >
                  Next
                </button>
              )}
              {steps === 1 && (
                <button
                  onClick={onOtpVerify}
                  className="btn shadow btn-info text-white btn-sm"
                >
                  Verify
                </button>
              )}
              {steps === 4 && formData?.paymentMode === "manual" && (
                <button
                  onClick={(event) => handleSignupSubmit(event)}
                  className="btn shadow btn-success text-white btn-sm"
                  disabled={formData?.pass?.length < 2 || !formData?.policy}
                >
                  Submit
                </button>
              )}
              {steps === 4 && formData?.paymentMode === "online" && (
                <button
                  onClick={(event) => handleSignupSubmit(event)}
                  className="btn shadow btn-success text-white btn-sm"
                  disabled={!formData?.policy}
                >
                  Submit
                </button>
              )}
            </div>
          
            {steps === 4 && (
              <>
                <ul className="mt-2">
                  <li>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formData?.instruction?.warning_info_1,
                      }}
                    ></div>
                  </li>
                  <li>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formData?.instruction?.warning_info_2,
                      }}
                    ></div>
                  </li>
                </ul>
              </>
            )}
          </form>
        </div>
      </div>
    </body>
  );
};

export default User;
