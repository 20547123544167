import axios from "axios";
import Swal from "sweetalert2";

// const api = axios.create({
//   baseURL: "http://savarpro.co.in/tublehouse",
// });
const api = axios.create({
  baseURL:
    "https://p7wxfqa97f.execute-api.ap-south-1.amazonaws.com/prod/user/api/v1/tublehouse",
});
const api2 = axios.create({
  baseURL:
    "https://p7wxfqa97f.execute-api.ap-south-1.amazonaws.com/prod/payu/api/v1/tublehouse",
});


const searchParentService = async (url, params) => {
  const queryString = new URLSearchParams(params).toString();
  try {
    const data = await api.get(`${url}?${queryString}`);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("search parent error", error);
  }
};

const numberDetailsService = async (url, params) => {
  const queryString = new URLSearchParams(params).toString();
  try {
    const data = await api.get(`${url}?${queryString}`);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("number details error", error);
  }
};

const slotDetailsService = async (url, params) => {
  
  const queryString = new URLSearchParams(params).toString();
  try {
    const data = await api.get(`${url}?${queryString}`);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("slot details error", error);
  }
};
const cashDataService = async (url, req) => {
  try {
    const data = await api.post(url, req);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("cash data error", error);
  }
};
const createSlotsService = async (url, req) => {
  try {
    const data = await api.post(url, req);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("slot data error", error);
  }
};
const adminLoginService = async (url, req) => {
  try {
    const data = await api.post(url, req);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("admin login error", error);
      Swal.fire({
        icon: "error",
        title: "",
        text: "Wrong password",
      });
  }
};
const deleteSlotService = async (url, req) => {
  // debugger;
  try {
    const data = await api.post(url, req);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("slot data error", error);
  
  }
};
const getDiscountService = async (url, params) => {
  try {
    const data = await api.get(url);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("discount details error", error);
  }
};
const createDiscountService = async (url, req) => {
  try {
    const data = await api.post(url, req);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("slot data error", error);
  }
};
const sendWhatsappOtpService = async (url, req) => {
  try {
    const data = await api.post(url, req);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("OTP Error", error);
  }
};
const verifyOtpService = async (url, req) => {
  try {
    const data = await api.post(url, req);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("OTP Error", error);
  }
};
const deleteDiscountService = async (url, req) => {
  // debugger;
  try {
    const data = await api.post(url, req);
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("slot data error", error);
  }
};
const onlinePaymentService = async (url, req) => {
  // debugger;
  try {
    const data = await api2.post(url, req);
    if (data) {
      // return data;
      // console.log("data from payment api",data?.data)
      window.open(data?.data?.link, "_top");
    }
  } catch (error) {
    console.error("payment service error", error);
  }
};
export {
  searchParentService,
  onlinePaymentService,
  numberDetailsService,
  slotDetailsService,
  cashDataService,
  createSlotsService,
  deleteSlotService,
  adminLoginService,
  getDiscountService,
  deleteDiscountService,
  createDiscountService,
  sendWhatsappOtpService,
  verifyOtpService
};
